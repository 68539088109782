import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/pro-regular-svg-icons";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
import ProudCoderSvg from "../images/undraw/undraw_proud_coder_7ain.svg";
import LogoCloud from "../components/LogoCloud";

const RubyOnRailsNewPage = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Ruby on Rails Consulting and Development" />
          <Card
            visible={cardVisible}
            header="Ruby on Rails Consulting and Development"
            previousUrl="/ruby-on-rails-new-3"
          >
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2">
              <div className="flex">
                <FontAwesomeIcon
                  icon={faLayerGroup}
                  size="2x"
                  className="text-magenta-900"
                />
                <p className="ml-5 text-base leading-6 text-gray-700 text-left mb-3">
                  Ruby on Rails comes with <strong>curated integrations</strong>{" "}
                  of all the{" "}
                  <strong>major state-of-the-art technologies</strong> you can
                  expect a modern web application to make use of (
                  <a
                    className="text-magenta hover:underline"
                    href="https://edgeguides.rubyonrails.org/active_storage_overview.html"
                  >
                    cloud storage
                  </a>
                  ,
                  <a
                    className="text-magenta hover:underline"
                    href="https://edgeguides.rubyonrails.org/active_storage_overview.html#transforming-images"
                  >
                    media processing
                  </a>
                  ,{" "}
                  <a
                    className="text-magenta hover:underline"
                    href="https://guides.rubyonrails.org/action_cable_overview.html"
                  >
                    real-time communication
                  </a>
                  ,{" "}
                  <a
                    className="text-magenta hover:underline"
                    href="https://guides.rubyonrails.org/active_job_basics.html"
                  >
                    asynchronous/background job processing
                  </a>
                  ,{" "}
                  <a
                    className="text-magenta hover:underline"
                    href="https://edgeguides.rubyonrails.org/action_text_overview.html"
                  >
                    rich text editing
                  </a>
                  ,{" "}
                  <a
                    className="text-magenta hover:underline"
                    href="https://edgeguides.rubyonrails.org/action_mailbox_basics.html"
                  >
                    inbound mail processing
                  </a>{" "}
                  etc.)
                </p>
              </div>
              <figure className="w-full">
                <img alt="proud coder" src={ProudCoderSvg} />
              </figure>
            </div>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "weavs",
                url: "https://www.weavs.io",
              },
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
              },
            ]}
          />
        </>
      )}
    </Layout>
  );
};

export default RubyOnRailsNewPage;
